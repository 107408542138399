export function init() {
  return { type: 'INIT_PLANNER' };
}

export function initSocket(payload) {
  return { type: 'INIT_SOCKET', payload };
}

export function resetDate({ startDate, endDate, open }) {
  return { type: 'RESET_DATE', startDate, endDate, open };
}

export function refresh({ changeZoom = true, onSuccess = () => {}, clearCurrentTasks = true } = {}) {
  return { type: 'REFRESH', changeZoom, onSuccess, clearCurrentTasks };
}

export function refreshAll(settings) {
  return { type: 'REFRESH_ALL', data: settings };
}

export function changeTaskFilter({ taskFilter, isLoadTasks }) {
  return { type: 'CHANGE_TASK_FILTER', taskFilter, isLoadTasks };
}

export function setTaskFilter(taskFilter) {
  return { type: 'SET_TASK_FILTER', taskFilter };
}

export function changeWorkerFilter({ worker, updateTasks }) {
  return { type: 'CHANGE_WORKER_FILTER', worker, updateTasks };
}

export function resetSettings(profile) {
  return { type: 'RESET_SETTINGS', data: profile };
}

export function selectStop({ taskIds, unSelect }) {
  return { type: 'SELECT_STOP', taskIds, unSelect };
}

export function selectTasks(tasks, select) {
  return { type: 'SELECT_TASKS', tasks, select };
}

export function selectWorker(worker, teamId) {
  return { type: 'SELECT_WORKER', worker, teamId };
}

export function deSelectWorker(worker, teamId) {
  return { type: 'DESELECT_WORKER', worker, teamId };
}

export function toggleSelection(payload) {
  return { type: 'TOGGLE_SELECTION', hideSelection: !!(payload && payload.hideSelection) };
}

export function hideSelectionOnPageChange() {
  return { type: 'HIDE_SELECTION_ON_PAGE_CHANGE' };
}

export function changeOrderItemIdFilter({ isLoadTasks, orderItemIds }) {
  return { type: 'CHANGE_ORDER_ITEM_IDS_FILTER', payload: { orderItemIds, isLoadTasks } };
}

export function changeSelectionMode(selectionMode) {
  return { type: 'CHANGE_SELECTION_MODE', selectionMode };
}

export function toggleActionMode(mode) {
  return { type: 'TOGGLE_ACTION_MODE', mode };
}

export function selectAllStops() {
  return { type: 'SELECT_ALL_STOPS' };
}

export function toggleSelectAllStops() {
  return { type: 'TOGGLE_SELECT_ALL_STOPS' };
}

export function deselectAllStop() {
  return { type: 'DESELECT_ALL_STOPS' };
}

export function selectBounds(bounds) {
  return { type: 'SELECT_BOUNDS', bounds };
}

export function clearZoneFilter({ isLoadTasks }) {
  return { type: 'CLEAR_ZONE_FILTER', isLoadTasks };
}

export function searchTaskRequest({ searchText } = {}) {
  return { type: 'SEARCH_TASK_REQUEST', searchText };
}

export function searchDriverTeam({ searchText }) {
  return { type: 'SEARCH_DRIVER_TEAM', searchText };
}

export function updateSearchVehiclesTeam({ searchTerm }) {
  return { type: 'UPDATE_VEHICLES_SEARCH_TERM', searchTerm };
}

export function toggleShowVehicleFilters() {
  return { type: 'TOGGLE_SHOW_VEHICLE_FILTERS' };
}

export function updateVehicleSortFilter(filter) {
  return { type: 'UPDATE_VEHICLE_SORT_FILTER', filter };
}

export function loadMoreTasks(resolve) {
  return { type: 'LOAD_MORE_TASK', resolve };
}

export function updateSelectedHubs({ selectedHubs }) {
  return { type: 'UPDATE_SELECTED_HUBS', selectedHubs };
}

export function clearHubFilter({ isLoadTasks }) {
  return { type: 'CLEAR_HUB_FILTER', isLoadTasks };
}

export function updateSelectedZones({ selectedZones }) {
  return { type: 'UPDATE_SELECTED_ZONES', selectedZones };
}

export function initNetwork() {
  return { type: 'INIT_NETWORK' };
}

export function initWorkers() {
  return { type: 'INIT_PLANNER_WORKER' };
}

export function initRegion() {
  return { type: 'INIT_REGION' };
}

export function initSettings() {
  return { type: 'INIT_SETTINGS' };
}

export function refreshCompanySettings() {
  return { type: 'REFRESH_COMPANY_SETTING' };
}

export function updateOptimizable({ optimizable }) {
  return { type: 'UPDATE_OPTIMIZABLE', optimizable };
}

export function getWorker(payload) {
  return { type: 'REQUEST_GET_WORKER', payload };
}

export function getVehicles(payload) {
  return { type: 'REQUEST_GET_VEHICLES', payload };
}

export function getVehicleSchedules(id) {
  return { type: 'REQUEST_GET_VEHICLE_SCHEDULES', id };
}

export function searchVehicles(payload) {
  return { type: 'REQUEST_SEARCH_VEHICLES', payload };
}

export function resetVehicles() {
  return { type: 'RESET_VEHICLES' };
}

export function updateSearchVehiclesFilter(filterType) {
  return { type: 'UPDATE_SEARCH_VEHICLES_FILTER', filterType };
}

export function searchWorkers(payload) {
  return { type: 'REQUEST_SEARCH_WORKERS', payload };
}

export function assignVehicleToDriver(payload) {
  return { type: 'REQUEST_ASSIGN_VEHICLE_TO_DRIVER', payload };
}

export function toggleAssignVehicleToDriverDialog(payload) {
  return { type: 'TOGGLE_ASSIGN_VEHICLE_TO_DRIVER_DIALOG', payload };
}

export function clearAssignVehicleToDriverMessage() {
  return { type: 'CLEAR_ASSIGN_VEHICLE_TO_DRIVER_MESSAGE' };
}

export function toggleShowWorkerFilter() {
  return { type: 'TOGGLE_SHOW_WORKER_FILTER' };
}

export function toggleShowWorkerSort() {
  return { type: 'TOGGLE_SHOW_WORKER_SORT' };
}

export function toggleWorkerFilterSort(filter) {
  return { type: 'TOGGLE_WORKER_FILTER_SORT', filter };
}

export function saveCopiedItemIds(ids) {
  return { type: 'SAVE_COPIED_ITEM_IDS', ids };
}

export function removeCopiedItemId(id) {
  return { type: 'REMOVE_COPIED_ITEM_ID', id };
}

export function updateFilterByQueryString(callbackAction) {
  return { type: 'UPDATE_FILTER_BY_QUERY_STRING', callbackAction };
}

export function applyTaskFilter(taskFilter) {
  return { type: 'APPLY_TASK_FILTER', taskFilter };
}

export function showMarkOn(id) {
  return { type: 'SHOW_MARK_ON', id };
}

export function showMarkOff() {
  return { type: 'SHOW_MARK_OFF' };
}
