import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';
import { Trans } from 'react-i18next';

import { isLocationDontUpdateInLast24Hours } from '../helpers';
import {
  DriverLocationIcon,
  DriverNoTaskAssignedIcon,
  DriverTaskAssignedIcon,
  DropoffTaskAssignedIcon,
  DropoffTaskCancelledIcon,
  DropoffTaskCompletedIcon,
  DropoffTaskReportedIcon,
  DropoffTaskUnassignedIcon,
  HubIcon,
  PartnerWorkerIcon,
  PickupTaskAssignedIcon,
  PickupTaskCancelledIcon,
  PickupTaskCompletedIcon,
  PickupTaskReportedIcon,
  PickupTaskUnassignedIcon,
  StopsAtLocationIcon,
} from './assets';
import {
  AssignedStatus,
  CancelledStatus,
  CompletedStatus,
  Driver,
  LegendItem,
  LegendItemKey,
  ReportedStatus,
  UnassignedStatus,
} from './types';

export const defaultLegendItems: LegendItem[] = [
  {
    icon: (
      <SvgIcon fontSize="small">
        <DriverTaskAssignedIcon />
      </SvgIcon>
    ),
    text: <Trans>Assigned driver</Trans>,
    key: 'assignedDriver',
  },
  {
    icon: (
      <SvgIcon fontSize="small">
        <DriverNoTaskAssignedIcon />
      </SvgIcon>
    ),
    text: <Trans>Unassigned driver</Trans>,
    key: 'unassignedDriver',
  },
  {
    icon: (
      <SvgIcon fontSize="small">
        <DriverLocationIcon />
      </SvgIcon>
    ),
    text: <Trans>Inactive driver</Trans>,
    key: 'inactiveDriver',
  },
  {
    icon: (
      <SvgIcon fontSize="small">
        <PartnerWorkerIcon />
      </SvgIcon>
    ),
    text: <Trans>Assigned partner driver</Trans>,
    key: 'assignedPartnerDriver',
  },
];

export const moreLegendItems: LegendItem[] = [
  {
    icon: (
      <>
        <SvgIcon fontSize="small">
          <PickupTaskUnassignedIcon />
        </SvgIcon>
        &nbsp;/&nbsp;
        <SvgIcon fontSize="small">
          <DropoffTaskUnassignedIcon />
        </SvgIcon>
      </>
    ),
    text: (
      <span>
        &nbsp;&nbsp;<Trans>Pickup</Trans> / <Trans>Dropoff</Trans>
      </span>
    ),
    key: 'unassigned',
  },
  {
    icon: (
      <>
        <SvgIcon fontSize="small">
          <PickupTaskAssignedIcon />
        </SvgIcon>
        &nbsp;/&nbsp;
        <SvgIcon fontSize="small">
          <DropoffTaskAssignedIcon />
        </SvgIcon>
      </>
    ),
    text: (
      <span>
        &nbsp;&nbsp;<Trans>Assigned</Trans>
      </span>
    ),
    key: 'assigned',
  },
  {
    icon: (
      <>
        <SvgIcon fontSize="small">
          <PickupTaskCompletedIcon />
        </SvgIcon>
        &nbsp;/&nbsp;
        <SvgIcon fontSize="small">
          <DropoffTaskCompletedIcon />
        </SvgIcon>
      </>
    ),
    text: (
      <span>
        &nbsp;&nbsp;<Trans>Completed</Trans>
      </span>
    ),
    key: 'completed',
  },
  {
    icon: (
      <>
        <SvgIcon fontSize="small">
          <PickupTaskReportedIcon />
        </SvgIcon>
        &nbsp;/&nbsp;
        <SvgIcon fontSize="small">
          <DropoffTaskReportedIcon />
        </SvgIcon>
      </>
    ),
    text: (
      <span>
        &nbsp;&nbsp;<Trans>Reported</Trans>
      </span>
    ),
    key: 'reported',
  },
  {
    icon: (
      <>
        <SvgIcon fontSize="small">
          <PickupTaskCancelledIcon />
        </SvgIcon>
        &nbsp;/&nbsp;
        <SvgIcon fontSize="small">
          <DropoffTaskCancelledIcon />
        </SvgIcon>
      </>
    ),
    text: (
      <span>
        &nbsp;&nbsp;<Trans>Cancelled</Trans>
      </span>
    ),
    key: 'cancelled',
  },
  {
    icon: (
      <SvgIcon fontSize="small">
        <StopsAtLocationIcon />
      </SvgIcon>
    ),
    text: <Trans>Multiple stops at location</Trans>,
    key: 'stopsAtLocation',
  },
  {
    icon: (
      <SvgIcon fontSize="small">
        <HubIcon />
      </SvgIcon>
    ),
    text: <Trans>Hub</Trans>,
    key: 'hub',
  },
];

export const LEGEND_DRIVER_KEY = [
  'assignedDriver',
  'unassignedDriver',
  'inactiveDriver',
  'assignedPartnerDriver',
] as const;

export const LEGEND_TASK_KEY = [
  'assigned',
  'unassigned',
  'completed',
  'reported',
  'cancelled',
  'stopsAtLocation',
] as const;
export const UNASSIGNED_STATUS = ['unassigned', 'transferred - pending', 'transferred - unassigned'] as const;
export const ASSIGNED_STATUS = ['assigned', 'accepted', 'transferred - assigned'] as const;
export const COMPLETED_STATUS = ['transferred - completed', 'completed'] as const;
export const REPORTED_STATUS = ['missing info', 'reported', 'transferred - reported'] as const;
export const CANCELLED_STATUS = ['cancelled', 'invalidated', 'pending'] as const;

type Status = AssignedStatus | UnassignedStatus | CompletedStatus | ReportedStatus | CancelledStatus;

type IDriver = (driver: Driver) => boolean;
type IStatus = (status: Status) => boolean;

export const DISABLED_LEGEND_CONDITION: Partial<Record<LegendItemKey, IDriver | IStatus>> = {
  assignedPartnerDriver: (driver: Driver) => !!driver.isPartnerWorker,
  inactiveDriver: (driver: Driver) => driver.ongoing_tasks_count === 0 && isLocationDontUpdateInLast24Hours(driver),
  assignedDriver: (driver: Driver) => driver.ongoing_tasks_count > 0,
  unassignedDriver: (driver: Driver) =>
    driver.ongoing_tasks_count <= 0 && (driver.ongoing_tasks_count !== 0 || !isLocationDontUpdateInLast24Hours(driver)),
  unassigned: (status: UnassignedStatus) => UNASSIGNED_STATUS.includes(status),
  assigned: (status: AssignedStatus) => ASSIGNED_STATUS.includes(status),
  completed: (status: CompletedStatus) => COMPLETED_STATUS.includes(status),
  reported: (status: ReportedStatus) => REPORTED_STATUS.includes(status),
  cancelled: (status: CancelledStatus) => CANCELLED_STATUS.includes(status),
};
