import './Map.scss';

import GoogleMapReact from 'google-map-react';
import React from 'react';
import { useSelector } from 'react-redux';

import { getMapData } from '@yojee/helpers/map-helper';
import * as zoomHelper from '@yojee/helpers/zoom-helper';

const bootstrapURLKeys = {
  key: process.env.REACT_APP_SCOPED_GOOGLE_API_KEY,
  libraries: ['drawing', 'places'].join(','),
  id: 'explore-maps-script',
};

const mapOptions = (map, customOptions = {}) => {
  return {
    zoomControlOptions: {
      position: map.ControlPosition.RIGHT_BOTTOM, // as long as this is not set it works
      style: map.ZoomControlStyle.SMALL,
    },
    fullscreenControlOptions: {
      position: map.ControlPosition.BOTTOM_RIGHT,
    },
    styles: undefined,
    scaleControl: true,
    streetViewControl: false,
    minZoom: zoomHelper.minZoom,
    maxZoom: zoomHelper.maxZoom,
    mapId: process.env.REACT_APP_GOOGLE_MAP_ID,
    ...customOptions,
  };
};

export const GoogleMap = ({
  onMapChange,
  handleGoogleMapApi,
  zoomControl = false,
  center = false,
  zoom = false,
  customMapOpts = {},
}) => {
  return (
    <GoogleMapReact
      options={(map) =>
        mapOptions(map, {
          zoomControl,
          ...customMapOpts,
        })
      }
      yesIWantToUseGoogleMapApiInternals
      bootstrapURLKeys={bootstrapURLKeys}
      center={center || zoomHelper.defaultCenter}
      zoom={zoom || zoomHelper.defaultZoom}
      onChange={onMapChange}
      onGoogleApiLoaded={handleGoogleMapApi}
      resetBoundsOnResize
    />
  );
};

const GoogleMapWithBoundControl = ({
  onMapChange,
  handleGoogleMapApi,
  mapKey = null,
  zoomControl = false,
  customMapOpts = {},
}) => {
  const { center, zoom } = useSelector((state) => getMapData(state, mapKey));

  return (
    <GoogleMap
      onMapChange={onMapChange}
      handleGoogleMapApi={handleGoogleMapApi}
      zoomControl={zoomControl}
      center={center}
      zoom={zoom}
      customMapOpts={customMapOpts}
    />
  );
};

export default React.memo(GoogleMapWithBoundControl);
