const defaultState = {
  loading: {
    getServiceTypes: false,
    getCompanyInfo: false,
    getJwtToken: false,
    getTaskTypes: false,
  },
  serviceTypes: [],
  taskTypes: [],
  nonOperatingDates: [],
  trackingMapData: {},
  companyInfo: {
    slug: null,
    documentServiceHost: null,
    branding: {
      style: {
        'admin-primary-color': {
          backgroundColor: '',
          borderColor: '',
        },
        'branding-background-color': {
          backgroundColor: '#ffffff',
          borderColor: '#ffffff',
        },
      },
    },
    currency: '',
    deliveryOptions: {},
    copy: {
      'default.unit': '',
      'default.volume_unit': '',
      'default.weight_unit': '',
    },
  },
  error: {
    failed: false,
    message: null,
  },
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  START_LOADING: (state, sagaData) => {
    return {
      ...state,
      loading: { ...state.loading, [sagaData.loadingAction]: true },
    };
  },

  GET_COMPANY_SLUG_SUCCESSFUL: (state, sagaData) => {
    const { slug } = sagaData;
    return {
      ...state,
      companyInfo: { ...state.companyInfo, slug },
    };
  },

  GET_DODUMENT_SERVICE_HOST_SUCCESSFUL: (state, sagaData) => {
    const { documentServiceHost } = sagaData;
    return {
      ...state,
      companyInfo: { ...state.companyInfo, documentServiceHost },
    };
  },
  GET_COMPANY_INFO_SUCCESSFUL: (state, sagaData) => {
    const data = sagaData.companyData;
    return {
      ...state,
      loading: { ...state.loading, getCompanyInfo: false },
      companyInfo: { ...state.companyInfo, ...data },
    };
  },
  GET_COMPANY_INFO_FAILED: (state, sagaData) => {
    const { error } = sagaData;
    return {
      ...state,
      loading: { ...state.loading, getCompanyInfo: false },
      error: {
        failed: true,
        message: error?.message ?? 'Failed to get company info',
      },
    };
  },
  GET_COMPANY_SERVICE_TYPES_SUCCESSFUL: (state, sagaData) => {
    const { data } = sagaData;
    return {
      ...state,
      serviceTypes: data['service_types'] ? data['service_types'] : [],
      nonOperatingDates: data['non_operating_dates'] ? data['non_operating_dates'] : [],
      loading: { ...state.loading, getServiceTypes: false },
    };
  },
  GET_COMPANY_SERVICE_TYPES_FAILED: (state, sagaData) => {
    const { error } = sagaData;
    return {
      ...state,
      loading: { ...state.loading, getServiceTypes: false },
      error: {
        failed: true,
        message: error?.message ?? 'Failed to get service types',
      },
    };
  },
  GET_JWT_TOKEN_SUCCESSFUL: (state, sagaData) => {
    const { data, trackingNumber } = sagaData;

    return {
      ...state,
      trackingMapData: {
        ...state.trackingMapData,
        [trackingNumber]: {
          jwt: data.jwt,
          workerId: data['worker_id'],
        },
      },
    };
  },
  GET_JWT_TOKEN_FAILED: (state, sagaData) => {
    const { trackingNumber } = sagaData;

    return {
      ...state,
      trackingMapData: {
        ...state.trackingMapData,
        [trackingNumber]: {
          jwt: null,
          workerId: null,
        },
      },
    };
  },
  SET_COMPANY_BRANDING_STYLE: (state, sagaData) => {
    return {
      ...state,
      companyInfo: { ...state.companyInfo, branding: { ...state.companyInfo.branding, ...sagaData.branding } },
    };
  },
  GET_TASK_TYPES_SUCCESSFUL: (state, sagaData) => {
    return {
      ...state,
      loading: { ...state.loading, getTaskTypes: false },
      taskTypes: sagaData && sagaData.data,
    };
  },
  GET_TASK_TYPE_FAILED: (state, sagaData) => {
    const { error } = sagaData;
    return {
      ...state,
      loading: { ...state.loading, getTaskTypes: false },
      error: {
        failed: true,
        message: error?.message ?? "Failed to get company's task types",
      },
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
