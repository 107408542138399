export const calculateZoom = ({ data, mapKey }) => {
  return { type: 'CALCULATE_ZOOM', data, mapKey };
};

export const zoomSpecific = ({ data, mapKey }) => {
  return { type: 'ZOOM_SPECIFIC', data, mapKey };
};

export const zoomSpecificRoute = (mapChangeProps) => {
  return { type: 'ZOOM_SPECIFIC_ROUTE', data: mapChangeProps };
};

export const zoomClear = (mapChangeProps) => {
  return { type: 'ZOOM_CLEAR', data: mapChangeProps };
};

export const selectTaskByMapSelection = (tasks) => {
  return { type: 'SELECT_TASK_BY_MAP_SELECTION', tasks };
};

export const updateMapLegend = ({ value, key }) => {
  return { type: 'UPDATE_MAP_LEGEND', value, key };
};
