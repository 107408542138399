import { call, debounce, put, select, takeLatest } from 'redux-saga/effects';

import { orderItemsService } from '@yojee/api/orderItemsService';
import { getValue } from '@yojee/helpers/access-helper';
import { AVAILABLE_FILTERS } from '@yojee/helpers/constants';
export const getOptimisationData = (state) => state.optimisation;
export const getMainData = (state) => state.main;
export const getPlanner = (state) => state.planner;
export const getNormalizedTask = (state) => state.planner && state.planner.taskData;
export const getSelectedWorker = (state) => state.planner && state.planner.filters && state.planner.filters.workers;
export const taskFilter = (state) => state.planner && state.planner.filters.taskFilter;
export const selectedWorkers = (state) => state.planner && state.planner.selectedWorkers;

function* updateVisibleRoutes({ workerId, visible, source }) {
  const { latestRequestId, requests } = yield select(getOptimisationData);
  const { routes } = yield select(getMainData);

  let visibleRoutes = [];
  const tasksToZoom = [];

  const routesConfig = {};
  const groupsConfig = {};

  if (source === 'planned') {
    visibleRoutes = routes.visiblePlanned ? [...routes.visiblePlanned] : [];
    (Array.isArray(workerId) ? workerId : [workerId]).forEach((id) => {
      if (visible) {
        visibleRoutes.push(id);
      } else {
        visibleRoutes = visibleRoutes.filter((w) => w !== id);
      }
    });
    routesConfig.visiblePlanned = visibleRoutes;
  } else if (source === 'assigned' || source === 'timeline') {
    visibleRoutes = routes.visibleAssigned ? [...routes.visibleAssigned] : [];
    if (visible) {
      visibleRoutes.push(workerId);
    } else {
      visibleRoutes = visibleRoutes.filter((w) => w !== workerId);
    }
    routesConfig.visibleAssigned = visibleRoutes;
  } else if (source === 'unassigned') {
    groupsConfig.visibleUnassigned = visible;
  }

  if (source === 'unassigned' || source === 'assigned' || source === 'timeline') {
    const { data, ids } = yield select(getNormalizedTask);
    ids
      .filter((id) => data[id].task_group.state === 'unassigned' && data[id].location)
      .forEach((id) => {
        tasksToZoom.push({ location: { lat: data[id].location.lat, lng: data[id].location.lng } });
      });
  } else if (source === 'planned') {
    if (requests?.[latestRequestId]) {
      const plannedData = requests[latestRequestId];
      const routes = getValue(plannedData, 'result.routes', []);
      routes &&
        routes
          .filter((r) => visibleRoutes.includes(parseInt(r.assignee.id)))
          .forEach((route) => {
            route.tour.forEach((t) => {
              tasksToZoom.push({ location: { lat: t.location.latitude, lng: t.location.longitude } });
            });
          });
    }
  }

  yield put({ type: 'SET_ROUTES_CONFIG', routes: routesConfig });
  yield put({ type: 'SET_GROUPS_CONFIG', groups: groupsConfig });
  yield put({ type: 'ZOOM_SPECIFIC', data: tasksToZoom });
}

function* updateFiltersAfterAssignment() {
  const stopFilterState = yield select(taskFilter);
  yield put({ type: 'SET_STOPS_LIST_CONFIG', stopsList: { filter: stopFilterState || AVAILABLE_FILTERS.UNASSIGNED } });
  yield handleStopFilterChange({ value: stopFilterState || AVAILABLE_FILTERS.UNASSIGNED, isLoadTasks: true });
  yield updateVisibleRoutes({ workerId: null, visible: false, source: null });
  yield put({ type: 'SET_RIGHT_PANEL_COMMAND', rightPanelCommand: 'collapse' });
  yield put({ type: 'TOGGLE_SELECTION', hideSelection: true });
  yield put({ type: 'TOGGLE_TEAM_SELECTION' });
}

function* handleStopFilterChange({ value, isLoadTasks }) {
  const { latestRequestId, requests } = yield select(getOptimisationData);
  yield put({ type: 'SET_STOPS_LIST_CONFIG', stopsList: { filter: value } });
  yield put({ type: 'CHANGE_TASK_FILTER', taskFilter: value, isLoadTasks });
  const filterWorkers = yield select(getSelectedWorker);

  // Clear the selection mode
  if (value === AVAILABLE_FILTERS.ASSIGNED) {
    yield put({ type: 'SET_RIGHT_PANEL_COMMAND', rightPanelCommand: 'collapse' });
    yield put({ type: 'SET_ROUTES_CONFIG', routes: { visiblePlanned: [], visibleAssigned: filterWorkers } });
  } else if (value === AVAILABLE_FILTERS.LAST_PLANNED) {
    if (requests?.[latestRequestId]) {
      const plannedData = requests[latestRequestId];
      const routes = getValue(plannedData, 'result.routes', []);
      const workerIdList = routes && routes.map((route) => parseInt(route.assignee.id));
      yield put({ type: 'SET_ROUTES_CONFIG', routes: { visiblePlanned: workerIdList } });
    }

    yield put({ type: 'SET_ROUTES_CONFIG', routes: { visibleAssigned: [] } });
  } else {
    yield put({
      type: 'SET_ROUTES_CONFIG',
      routes: { visibleAssigned: value === AVAILABLE_FILTERS.ALL ? filterWorkers : [], visiblePlanned: [] },
    });
  }
}

function* displayOptimisationResult({ resultData, requestId }) {
  yield put({ type: 'SET_LATEST_REQUEST_ID', latestRequestId: requestId });
  yield put({ type: 'SET_STOPS_LIST_CONFIG', stopsList: { filter: AVAILABLE_FILTERS.LAST_PLANNED } });
  yield put({ type: 'SET_OPTIMISATION_CONFIG', stopsList: { requestId } });
  yield put({
    type: 'UPDATE_VISIBLE_ROUTES',
    workerId: (resultData?.routes ?? []).map((route) => parseInt(route.assignee.id, 10)).slice(0, 5),
    visible: true,
    source: 'planned',
  });
  yield put({ type: 'TOGGLE_SELECTION', hideSelection: true });
  yield put({ type: 'TOGGLE_TEAM_SELECTION' });
  yield put({ type: 'SET_RIGHT_PANEL_COMMAND', rightPanelCommand: 'collapse' });
}

function* updateAllVisibleRoutes({ visible, source }) {
  const { latestRequestId, requests } = yield select(getOptimisationData);
  if (source === AVAILABLE_FILTERS.LAST_PLANNED && requests?.[latestRequestId]) {
    const routes = requests?.[latestRequestId]?.result?.routes ?? [];

    let visibleRoutes = [];
    if (visible && routes) {
      visibleRoutes = routes.map((r) => parseInt(r.assignee.id));
    }

    yield put({ type: 'SET_ROUTES_CONFIG', routes: { visiblePlanned: visibleRoutes } });
  }
}

function* showStopLegs({ tasks, anchorEl, stopId }) {
  yield put({ type: 'SET_STOPS_LIST_EXPANDED_LEGS', expandedLegs: { tasks, anchorEl, stopId } });
  if (tasks !== null) {
    yield put({ type: 'START_LEGS_FETCH', tasks });
  }
}

function* showHighlightedTasks({ highlightedTasks }) {
  yield put({ type: 'SET_HIGHLIGHTED_TASKS', highlightedTasks });
}

function* openAssigmentConfirmation() {
  yield put({ type: 'SET_ASSIGNMENT_CONFIG', assignment: { showConfirmationDialog: true, timelineSave: false } });
  const { selectedTasks } = yield select(getPlanner);
  const { ids, data } = yield select(getNormalizedTask);
  yield put({
    type: 'START_LEGS_FETCH_FROM_ES',
    tasks: ids.filter((id) => selectedTasks.includes(id)).map((id) => data[id]),
  });
}

// highlightedLines, highlightedTasks
function* handleTimelineItemSelect({ action, item }) {
  const { highlightedLines } = yield select(getMainData);
  if (action === 'unselect' || action === 'onMouseLeave') {
    if (highlightedLines) {
      yield put({ type: 'SET_HIGHLIGHTED_LINES', highlightedLines: null });
      yield put({ type: 'SET_HIGHLIGHTED_TASKS', highlightedTasks: null });
    }
    return;
  }
  if (item.type === 'node') {
    yield put({
      type: 'SET_HIGHLIGHTED_TASKS',
      highlightedTasks: item.itemProps['data-tip'].tasks.map((task) => task.id),
    });
  } else {
    const link = item.itemProps['data-tip'];
    const nodeFrom = link.from;
    const nodeTo = link.to;
    const stopFrom = nodeFrom.itemProps['data-tip'];
    const stopTo = nodeTo.itemProps['data-tip'];

    yield put({
      type: 'SET_HIGHLIGHTED_LINES',
      highlightedLines: [{ from: stopFrom.tasks[0].id, to: stopTo.tasks[0].id }],
    });
  }
}

function* handleCancelTasks({ tasks, forceCancellation, cancelled_notes, reason_code }) {
  try {
    const ids = tasks.map((t) => t.order_item.id);
    const distinctIds = [...new Set(ids)];
    const result = yield call(orderItemsService.cancelMultiple, {
      ids: distinctIds,
      forceCancellation,
      cancelled_notes,
      reason_code,
    });
    yield put({
      type: 'PROCESS_CANCEL_TASKS_SUCCESS',
      message: result.message,
    });
  } catch (err) {
    yield put({
      type: 'PROCESS_CANCEL_TASKS_ERROR',
      error: err.message && err.message.error ? JSON.stringify(err.message.error) : err.message,
    });
  }
}

function* refreshPresentationView() {
  yield put({
    type: 'UPDATE_LAST_REFRESH_TIME',
    lastRefreshAt: Date.now(),
  });
}

export default function* sagas() {
  yield takeLatest('UPDATE_VISIBLE_ROUTES', updateVisibleRoutes);
  yield takeLatest('UPDATE_ALL_VISIBLE_ROUTES', updateAllVisibleRoutes);
  yield takeLatest('UPDATE_FILTERS_AFTER_ASSIGNMENT', updateFiltersAfterAssignment);
  yield takeLatest('HANDLE_STOP_FILTER_CHANGE', handleStopFilterChange);
  yield takeLatest('DISPLAY_OPTIMISATION_RESULT', displayOptimisationResult);
  yield debounce(400, 'SHOW_STOP_LEGS', showStopLegs);
  yield debounce(250, 'SHOW_HIGHLIGHTED_TASKS', showHighlightedTasks);
  yield takeLatest('OPEN_ASSIGNMENT_CONFIRMATION', openAssigmentConfirmation);
  yield takeLatest('HANDLE_TIMELINE_ITEM_SELECT', handleTimelineItemSelect);
  yield takeLatest('PROCESS_CANCEL_TASKS', handleCancelTasks);
  yield takeLatest('FETCH_TASKS_COMPLETE', refreshPresentationView);
}
