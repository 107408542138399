/* eslint-disable no-undef */
// eslint-disable-next-line no-unused-vars
import { groupBy } from 'lodash-es/collection';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getValue } from '@yojee/helpers/access-helper';
import { AVAILABLE_FILTERS } from '@yojee/helpers/constants';
import { getColorByTaskStatus } from '@yojee/helpers/stop-helper';
import { getTransferredSubStatus } from '@yojee/helpers/transferred-helper';
import * as plannerActions from '@yojee/ui/planner/actions';

import hubIcon from './hub-icon.png';

export default function MyWrapper(props) {
  const dispatch = useDispatch();
  const { map, google, markers, taskTypes = [], plannedPositions } = props;
  const highlightedTasks = useSelector(
    (state) => state.main && state.main.stopsList && state.main.stopsList.highlightedTasks
  );
  const [taskMarkerHighlighted, setTaskMarkerHighlighted] = useState([]);
  const company = useSelector((state) => getValue(state, 'auth.dispatcher_info.data.company', {}));
  const markOnId = useSelector((state) => state.planner && state.planner?.taskData?.markOnId);
  const stopFilter = useSelector((state) => state && state.main && state?.main?.stopsList?.filter);
  const masterFilter = useSelector((state) => state.masterFilter);
  const isShowExternalCustomerId =
    company &&
    company.display_settings &&
    company.display_settings.admin_access &&
    company.display_settings.admin_access['items.show.external_customer_id'];
  useEffect(() => {
    if (google && markers) {
      new MC({
        markOnId,
        map,
        google,
        markers,
        taskTypes,
        highlightedTasks,
        isShowExternalCustomerId,
        dispatch,
        plannedPositions,
        stopFilter,
        masterFilter,
      });
    }
  }, [google, markers, markOnId, stopFilter]);
  useEffect(() => {
    const stopsMarkers = markers.filter((m) => m.data && m.data.stops);
    let highlightedMarkerId = [];
    if (!highlightedTasks || highlightedTasks.length < 1 || taskMarkerHighlighted.length > 0) {
      taskMarkerHighlighted.forEach((markerId) => {
        const elementByid = document.getElementById(markerId);
        if (elementByid) {
          elementByid.className = elementByid.className.replace(' highlighted-marker', '');
        }
      });
      setTaskMarkerHighlighted([]);
    }
    if (stopsMarkers && stopsMarkers.length > 0 && highlightedTasks) {
      stopsMarkers.forEach((stopMarker) => {
        const stops = stopMarker.data.stops;
        const tasksIds = stops.reduce((previousIds, stop) => {
          if (stop && stop.tasks && stop.tasks.length > 0) {
            return previousIds.concat(
              stop.tasks.reduce((pt, t) => {
                return pt.concat(t.id);
              }, [])
            );
          }
          return previousIds;
        }, []);
        for (let i = 0; i < highlightedTasks.length; i++) {
          const taskId = highlightedTasks[i];
          if (tasksIds.includes(taskId)) {
            highlightedMarkerId = highlightedMarkerId.concat(`marker-${stopMarker.id}`);
            break;
          }
        }
      });
    }
    if (highlightedMarkerId && highlightedMarkerId.length > 0) {
      highlightedMarkerId.forEach((markerId) => {
        const elementByid = document.getElementById(markerId);
        if (elementByid) {
          elementByid.className = elementByid.className + ' highlighted-marker';
        }
      });
    }
    setTaskMarkerHighlighted(highlightedMarkerId);
  }, [highlightedTasks]);
  return null;
}

class MC extends google.maps.OverlayView {
  constructor({
    markOnId,
    map,
    google,
    markers,
    taskTypes,
    highlightedTasks,
    isShowExternalCustomerId,
    dispatch,
    plannedPositions,
    stopFilter,
    masterFilter,
  }) {
    super();
    this.bindAll();
    this.dispatch = dispatch;
    this.google = google;
    this.taskTypes = taskTypes;
    this.highlightedTasks = highlightedTasks;
    this.markers = markers;
    this.markOnId = markOnId;
    this.boundInfo = markers.map((marker) => {
      const center = this.boundsOfCircle({ lat: marker.lat, lng: marker.lng }, 1);
      return { center, marker };
    });
    this.markerElements = [];
    this.markerDisplay = [];
    this.map = map;
    this.isShowExternalCustomerId = isShowExternalCustomerId;
    this.changeHandler = this.google.maps.event.addListener(this.map, 'bounds_changed', this.handleBoundChange);
    this.setMap(map);
    this.plannedPositions = plannedPositions;
    this.stopFilter = stopFilter;
    this.masterFilter = masterFilter;
  }

  handleBoundChange = () => {
    const newBounds = this.map.getBounds();
    const panes = this.getPanes();
    const currentOverlay = panes.floatPane;
    if (!currentOverlay) {
      return;
    }
    currentOverlay.innerHTML = '';
    for (let i = 0; i < this.markers.length; i++) {
      const marker = this.markers[i];
      const isInBound = newBounds.contains({ lat: marker.lat, lng: marker.lng });
      if (isInBound) {
        const validMarker = this.markerElements.filter((e) => e.id === marker.id);
        if (validMarker && validMarker.length > 0) {
          panes.floatPane.appendChild(validMarker[0].div);
        }
        if (!this.markerDisplay.find((m) => m.id === marker.id)) {
          this.markerDisplay = this.markerDisplay.concat(this.markerElements.filter((e) => e.id === marker.id));
        }
      } else {
        const removedMarker = this.markerDisplay.find((m) => m.id === marker.id);
        if (removedMarker) {
          this.markerDisplay = this.markerDisplay.filter((m) => m.id !== marker.id);
        }
      }
    }
  };

  boundsOfCircle = (center, radius) => {
    const circle = new google.maps.Circle();
    circle.setCenter(center);
    circle.setRadius(radius);
    return circle.getBounds();
  };

  bindAll = () => {
    for (const all in this) {
      this.bind(all);
    }
  };

  bind = (name) => {
    const method = this[name];
    if (typeof method != 'function') {
      return;
    }
    this[name] = function () {
      // eslint-disable-next-line prefer-rest-params
      return method.apply(this, arguments);
    };
  };
  createHubInfo = (hub) => {
    const popupInfo = document.createElement('div');
    popupInfo.className = 'info-container';
    const markerContent = document.createElement('div');
    markerContent.className = 'info-marker-content row-display';
    const hubName = document.createElement('div');
    hubName.innerText = `${hub.name}`;
    markerContent.appendChild(hubName);
    popupInfo.appendChild(markerContent);
    return popupInfo;
  };

  createStopInfo = (stops) => {
    const popupInfo = document.createElement('div');
    popupInfo.className = 'info-container';

    const taskInCurrentLocation = stops.reduce(
      (previous, current) =>
        previous.concat(
          current?.tasks?.map?.((task) => {
            if (task) {
              const transferredSubStatus = getTransferredSubStatus(task);
              task.stateForGroup = transferredSubStatus
                ? `transferred - ${transferredSubStatus}`
                : task?.task?.state === 'completed'
                ? 'completed'
                : task?.task_group?.state;
              if (task.stateForGroup === 'invalidated') {
                task.stateForGroup = task?.task?.['cancelled_time'] ? 'cancelled' : 'completed';
              }
            }

            return task;
          }) ?? []
        ),
      []
    );

    const taskNumber = taskInCurrentLocation.length;
    const groupTaskByState = groupBy(taskInCurrentLocation, 'stateForGroup');
    const keys = Object.keys(groupTaskByState);
    keys.forEach((key) => {
      const keyArray = groupTaskByState[key];
      if (keyArray?.length > 0) {
        // group by type
        const tasksByType = groupBy(keyArray, 'type');
        Object.keys(tasksByType).forEach((taskType) => {
          const arrayBykey = tasksByType[taskType];
          const popupInfoTitle = document.createElement('div');
          popupInfoTitle.className = 'info-address-marker-title';
          popupInfoTitle.innerText = taskNumber < 2 ? key : `${this.getTaskTypeMarkerText(null, taskType)} • ${key}`;
          popupInfoTitle.style.color = getColorByTaskStatus(key);
          popupInfo.appendChild(popupInfoTitle);
          if (arrayBykey && arrayBykey.length > 0) {
            arrayBykey.forEach((t) => {
              const content = document.createElement('div');
              content.onmousedown = () => {
                this.dispatch &&
                  this.dispatch(
                    plannerActions.searchTaskRequest({
                      searchText:
                        this.isShowExternalCustomerId && t.order_item.external_customer_id
                          ? t.order_item.external_customer_id
                          : t.order_item.tracking_number,
                    })
                  );
                this.map.setOptions({
                  scrollwheel: true,
                });
              };
              content.innerText =
                this.isShowExternalCustomerId && t.order_item.external_customer_id
                  ? t.order_item.external_customer_id
                  : t.order_item.tracking_number;
              content.className = 'info-marker-content';
              popupInfo.appendChild(content);
            });
          }
        });
      }
    });
    return popupInfo;
  };

  getTaskTypeMarkerText = (taskOnly, taskType) => {
    const convertType = taskType === 'pickup' ? 'P' : 'D';
    let text = convertType;

    if (Array.isArray(this.taskTypes) && this.taskTypes.length > 0) {
      const type = this.taskTypes.find((t) => t.slug === taskType);
      text = type ? type.marker_text : '';
    }

    if (
      this.stopFilter === AVAILABLE_FILTERS.ASSIGNED &&
      this.masterFilter?.basic?.driverIds.length !== 0 &&
      taskOnly
    ) {
      const { task } = taskOnly;
      text = task?.position >= 0 ? task?.position + 1 : convertType;
    }

    return text;
  };

  onAdd = () => {
    for (let i = 0; i < this.boundInfo.length; i++) {
      const currentBound = this.boundInfo[i];
      const currentMarker = this.markers[i];
      const popupInfo = this.createPopupInfo(currentMarker.data);
      const div = this.createMarker(currentMarker.data, currentBound.marker.id);
      const tooltiptext = document.createElement('div');
      tooltiptext.onmouseenter = () => {
        this.map.setOptions({
          scrollwheel: false,
        });
      };
      tooltiptext.onmouseleave = () => {
        this.map.setOptions({
          scrollwheel: true,
        });
      };
      tooltiptext.className = 'tooltiptext';
      popupInfo && tooltiptext.appendChild(popupInfo);
      div.appendChild(tooltiptext);
      this.markerElements = this.markerElements.concat({ id: currentBound.marker.id, div, marker: currentMarker });
    }
    this.handleBoundChange();
  };

  onRemove = () => {
    if (!this.map) {
      return;
    }
    this.map = null;
    if (this.changeHandler) {
      this.google.maps.event.removeListener(this.changeHandler);
      this.changeHandler = null;
    }
  };
  draw = () => {
    const overlayProjection = this.getProjection();
    for (let i = 0; i < this.markerDisplay.length; i++) {
      const currentMarker = this.markerDisplay[i];
      const boundInfo = this.boundInfo.find((b) => b.marker.id === currentMarker.id);
      const point = boundInfo.center;
      const sw = overlayProjection.fromLatLngToDivPixel(point.getSouthWest());
      const ne = overlayProjection.fromLatLngToDivPixel(point.getNorthEast());
      const div = currentMarker.div;
      div.style.top = ne.y + 'px';
      div.style.left = sw.x - 11 + 'px';
    }
  };

  onRemove = () => {
    this.markerElements = [];
  };

  createPopupInfo = (inputData) => {
    const { hub, stops } = inputData;
    return hub ? this.createHubInfo(hub) : stops ? this.createStopInfo(stops) : undefined;
  };

  createHubMarker = (hub, divContainer) => {
    const img = document.createElement('img');
    img.src = hubIcon;
    img.className = 'hub-icon';
    divContainer.appendChild(img);
    divContainer.style.display = 'flex';
    divContainer.style.justifyContent = 'center';
    divContainer.style.alignItems = 'center';
    divContainer.style.border = 'none';
    divContainer.style.boxShadow = 'none';
    return divContainer;
  };

  createStopMarker = (stops, divContainer) => {
    const taskNumber = stops.reduce((previous, current) => {
      return current?.tasks?.length > 0 ? previous + current.tasks.length : previous;
    }, 0);
    const taskInCurrentLocation = stops.reduce(
      (previous, current) =>
        previous.concat(
          current?.tasks?.map?.((task) => {
            return task;
          }) ?? []
        ),
      []
    );
    let color = '#0b203d';
    let text = taskNumber;

    const taskOnly = stops[0].tasks[0];
    if (taskNumber < 2) {
      const transferredSubStatus = getTransferredSubStatus(taskOnly);
      const status = transferredSubStatus
        ? `transferred - ${transferredSubStatus}`
        : taskOnly?.task?.state === 'completed'
        ? 'completed'
        : taskOnly?.task_group?.state;
      const taskType = taskOnly.type;
      color = getColorByTaskStatus(status);
      text = this.getTaskTypeMarkerText(taskOnly, taskType);
    }

    const position = this.plannedPositions?.[taskInCurrentLocation[0].id] ?? undefined;
    const allSamePosition = taskInCurrentLocation.every((t) => this.plannedPositions?.[t.id] === position);
    if (allSamePosition && position !== undefined) {
      text = position;
      color = taskInCurrentLocation[0].color;
    }

    divContainer.onmousedown = () => {
      const stopInfo =
        this.isShowExternalCustomerId && taskOnly.order_item.external_customer_id
          ? taskOnly.order_item.external_customer_id
          : taskOnly.order_item.tracking_number;
      this.dispatch(plannerActions.searchTaskRequest({ searchText: stopInfo }));
      this.map.setOptions({
        scrollwheel: true,
      });
    };

    if (position) {
      divContainer.className = 'marker-icon-container-position tooltip';
    }

    divContainer.onmousedown = () => {
      const stopInfo =
        this.isShowExternalCustomerId && taskOnly.order_item.external_customer_id
          ? taskOnly.order_item.external_customer_id
          : taskOnly.order_item.tracking_number;
      this.dispatch?.(plannerActions.searchTaskRequest({ searchText: stopInfo }));
      this.map.setOptions({
        scrollwheel: true,
      });
    };

    const spanText = document.createElement('div');
    spanText.innerText = text;
    spanText.className = 'icon-stop-text';
    divContainer.style.backgroundColor = color;
    divContainer.style.zIndex = 100;
    divContainer.appendChild(spanText);
    return divContainer;
  };

  createMarker = (inputData, id) => {
    const { hub, stops } = inputData;
    const divContainer = document.createElement('div');
    divContainer.className = 'marker-icon-container tooltip';
    if (stops && stops.some((item) => item.order_step_id === this.markOnId))
      divContainer.className = 'marker-icon-container active tooltip';
    divContainer.id = `marker-${id}`;
    return hub
      ? this.createHubMarker(hub, divContainer)
      : stops
      ? this.createStopMarker(stops, divContainer)
      : undefined;
  };
}

export const MarkersCanvas = (props) => {
  useEffect(() => {
    let markersCanvas;
    if (props.map && props.google) {
      markersCanvas = new MC(props);
    }

    return () => {
      if (markersCanvas) {
        markersCanvas.onRemove();
      }
    };
  }, [props]);

  return null;
};
