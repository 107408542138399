import { groupBy as groupByLd, orderBy as orderByLd } from 'lodash-es';
import set from 'lodash-es/set';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { AVAILABLE_VIEWS, PAIR_TASK_TYPE_POST_FIX } from '@yojee/helpers/constants';
import { logData } from '@yojee/helpers/LogHelper';
import { getTaskState } from '@yojee/helpers/TaskStateHelper';
import { getTransferredSubStatus, isTransferredCompletedTask } from '@yojee/helpers/TransferredHelper';
import { fromVolumeUnitAToVolumeUnitB } from '@yojee/helpers/unitConverter/volumeConverter';

export const getIdAndWorkerDataMapSelector = (state) => state.worker?.idAndWorkerDataMap;
export const getPlannerData = (state) => state.planner;
export const getMapData = (state) => state.map;
export const getTaskData2 = (state) => state.planner && state.planner.taskData;
export const getSelectedTasks = (state) => state.planner && state.planner.selectedTasks;
export const getSettings = (state) => state.planner && state.planner.settings;
export const getSelectedHubs = (state) => state.planner && state.planner.selectedHubs;
export const getZonesData = (state) => state.planner && state.planner.regionsData;
export const getRegionFilter = (state) =>
  state.planner && state.planner.filters && state.planner.filters.selectedRegions;
export const getSelectedWorker = (state) => state.planner && state.planner.filters && state.planner.filters.workers;
export const getTaskFilter = (state) => state.taskFilter && state.taskFilter.filter;
export const getMasterFilter = (state) => state.masterFilter;
export const getCurrentTaskState = (state) =>
  state.planner && state.planner.filters && state.planner.filters.taskFilter;
export const getSearchTask = (state) => state.planner && state.planner.searchTasks;
export const getCurrentStopView = (state) => state.main && state.main.stopsList.currentView;
export const getCurrentListView = (state) => state.itemsTable && state.itemsTable.currentView;
export const getShowList = (state) => state.main && state.main.showList;
export const getETASelector = (state) => state.tasks?.eta;
export const getETAFromSocketSelector = (state) => state.tasks?.etaFromSocket;
export const isPairTaskIncluded = (taskTypes = []) =>
  taskTypes.some((taskType) => taskType.includes(PAIR_TASK_TYPE_POST_FIX));

export const isTaskSelectedSelector = createSelector(
  [getSelectedTasks, (_, task) => task],
  (selectedTasks, task) => !!(Array.isArray(selectedTasks) && selectedTasks.includes(task.id))
);
export const isAnyOfTaskIdsSelectedSelector = createSelector(
  [getSelectedTasks, (_, taskIds) => taskIds],
  (selectedTasks, taskIds) => !!(Array.isArray(selectedTasks) && taskIds.find((id) => selectedTasks.includes(id)))
);
// Return first task ETA and last task ETA
export const getTasksEta = createSelector(
  [
    getETASelector,
    getETAFromSocketSelector,
    (_, tasks) => tasks?.filter((t) => !!t?.task?.id) || [],
    (_, tasks) =>
      tasks?.filter((t) => !!t?.task?.root_order_item_step_id).map((t) => t.task.root_order_item_step_id) || [],
  ],
  (ETAs, ETAFromSockets, tasks, rootOrderItemStepIds) => {
    const taskIds = tasks.map((t) => t.task.id);
    // Get eta from socket first (because it is the latest data), if not then from api
    const firstTaskId = taskIds[0];
    const lastTaskId = taskIds[taskIds.length - 1];

    const firstRootOrderItemStepId = rootOrderItemStepIds[0];
    const lastRootOrderItemStepId = rootOrderItemStepIds[rootOrderItemStepIds.length - 1];

    return {
      nextTaskETA: ETAFromSockets?.[firstTaskId] || ETAs?.[firstRootOrderItemStepId] || null,
      lastTaskETA: ETAFromSockets?.[lastTaskId] || ETAs?.[lastRootOrderItemStepId] || null,
      from: tasks[0]?.from,
      to: tasks[0]?.to,
    };
  }
); // State Access Functions
export const getWorkers = (state) => state.worker && state.worker.data;

/**
 * When driver.last_seen - 30 mins > driver.location_updated_at then we will show
 * driver's location not updated
 * @param driver
 * @returns {boolean}
 */
export const isDriverLocationNotUpdatedRecently = (driver) => {
  const { last_seen, location_updated_at } = driver || {};

  if (!last_seen || !location_updated_at) {
    return true;
  }

  return moment.utc(last_seen).subtract(30, 'minutes').isAfter(moment.utc(location_updated_at));
};

export const ETA_STATUSES = {
  IN_PAST: 'IN_PAST',
  IN_LONG_FUTURE: 'IN_LONG_FUTURE',
  IN_TODAY_AND_RECENTLY_UPDATED: 'IN_TODAY_AND_RECENTLY_UPDATED',
  IN_TODAY_BUT_NOT_UPDATED_RECENTLY: 'IN_TODAY_BUT_NOT_UPDATED_RECENTLY',
};

export const getStatusOfETA = (etaTimeInUtc, etaLastUpdatedTimeInUtc) => {
  const nowInUTC = moment.utc();

  if (etaTimeInUtc.isBefore(nowInUTC)) {
    return ETA_STATUSES.IN_PAST;
  }

  if (etaTimeInUtc.subtract(24, 'hours').isSameOrAfter(nowInUTC)) {
    return ETA_STATUSES.IN_LONG_FUTURE;
  } else {
    if (etaLastUpdatedTimeInUtc && etaLastUpdatedTimeInUtc.add(30, 'minutes').isSameOrAfter(nowInUTC)) {
      return ETA_STATUSES.IN_TODAY_AND_RECENTLY_UPDATED;
    } else {
      return ETA_STATUSES.IN_TODAY_BUT_NOT_UPDATED_RECENTLY;
    }
  }
};

export const getColorOfETATextFromETAStatus = (etaStatus) => {
  switch (etaStatus) {
    case ETA_STATUSES.IN_PAST:
      return '#dd5147';
    case ETA_STATUSES.IN_LONG_FUTURE:
      return '#6a7178';
    case ETA_STATUSES.IN_TODAY_AND_RECENTLY_UPDATED:
      return '#499f68';
    case ETA_STATUSES.IN_TODAY_BUT_NOT_UPDATED_RECENTLY:
      return '#ffaf21';
    default:
      return 'inherit';
  }
};

export const getTooltipOfETATextFromETAStatus = (etaStatus) => {
  switch (etaStatus) {
    case ETA_STATUSES.IN_PAST:
      return 'ETA is in the past!';
    case ETA_STATUSES.IN_LONG_FUTURE:
      return 'ETA > 24 hrs from now';
    case ETA_STATUSES.IN_TODAY_AND_RECENTLY_UPDATED:
      return 'ETA < 24 hrs from now and updated < 30 min ago';
    case ETA_STATUSES.IN_TODAY_BUT_NOT_UPDATED_RECENTLY:
      return 'ETA < 24 hrs from now and updated > 30 min ago';
    default:
      return '';
  }
};

export const isCompletedTask = (task) => {
  return getTaskState(task) === 'completed' || getTransferredSubStatus(task) === 'completed';
};

export const isAssignedTask = (task) => {
  return task?.['task_group']?.state === 'assigned';
};

export const isCancelledTask = (task) => {
  return getTaskState(task) === 'cancelled';
};

export const filterCompletedTasks = ({ tasks = [], selectedTaskIds = [] }) => {
  return selectedTaskIds?.filter((id) => isCompletedTask(tasks?.[id]));
};

export const filterCancelledTasks = ({ tasks = [], selectedTaskIds = [] }) => {
  return selectedTaskIds?.filter((id) => isCancelledTask(tasks?.[id]));
};

export const getExploreTaskTitle = (task, isShowExternalCustomerId, isUsingContainer) => {
  const orderItem = task?.order_item || {};
  const itemContainerNo = task?.item?.item_container?.container_no;

  if (isUsingContainer && itemContainerNo) {
    return itemContainerNo;
  }

  if (isShowExternalCustomerId && orderItem.external_customer_id) {
    return orderItem.external_customer_id;
  }

  return orderItem.tracking_number;
};

export const convertVolume = (value, unit, expectedUnit) => {
  return fromVolumeUnitAToVolumeUnitB(value, unit, expectedUnit);
};

export function fixSequence(tasks) {
  const invalidOrderItems = getInvalidOrderItemIds(tasks);
  logData({ module: 'tasksHelper', message: 'invalid order items', data: invalidOrderItems });
  return tasks
    .filter((t) => !invalidOrderItems.includes(t['order_item_id']))
    .concat(
      ...[].concat(
        ...invalidOrderItems.map((orderItemId) => sortSequence(tasks.filter((t) => t['order_item_id'] === orderItemId)))
      )
    );
}

const getInvalidOrderItemIds = (tasks) => {
  const orderItemIdToTaskMap = groupByLd(tasks, 'order_item_id');
  return Object.keys(orderItemIdToTaskMap)
    .filter((order_item_id) =>
      orderItemIdToTaskMap[order_item_id].some((task, index) => {
        if (index === 0) {
          return false;
        }
        const prevTask = orderItemIdToTaskMap[order_item_id][index - 1];
        return task['step_group'] < prevTask['step_group'];
      })
    )
    .map((id) => parseInt(id));
};

const sortSequence = (tasks) => {
  return orderByLd(tasks, ['step_group', 'step_sequence']);
};

export const getPrecedencesMap = (tasks) =>
  tasks.reduce((a, b) => {
    if (b.clusteredTasks) {
      b.clusteredTasks.forEach((clusteredTask) => {
        set(a, [clusteredTask['order_item_id'], clusteredTask['step_group'], clusteredTask['step_sequence']], b.id);
      });
    }

    set(a, [`${b['order_item_id']}`, `${b['step_group']}`, `${b['step_sequence']}`], b.id);
    return a;
  }, {});

export const isDescendantTaskSelected = ({
  stepSequence,
  orderItemId,
  tour,
  selectedTasks,
  returnFirstSelectedDescendantTask = false,
}) => {
  const descendantTasks = tour.filter(
    (t) => t?.task?.['step_sequence'] > stepSequence && t?.task?.['order_item_id'] === orderItemId
  );
  const matchingFunction = ({ id }) => selectedTasks.includes(parseInt(id));
  return returnFirstSelectedDescendantTask
    ? descendantTasks.find(matchingFunction)
    : descendantTasks.some(matchingFunction);
};

export const getCompletedTimeOfTask = (task) => {
  return isTransferredCompletedTask(task)
    ? task?.transfer_task_metadata?.task_info?.completion_time
    : task?.task?.completion_time;
};

export const getCompletionQuantityOfTask = (task) => {
  return isTransferredCompletedTask(task)
    ? task?.transfer_task_metadata?.task_info?.completion_quantity
    : task?.task?.completion_quantity;
};

export const useGetCompletedQuantity = ({
  numberOfTasks,
  numberOfCompletedTasks,
  tasks,
  taskIds,
  isShowCompletedQuantity,
}) => {
  const currentTableView = useSelector((state) => state.itemsTable?.currentView);

  return React.useMemo(() => {
    if (!isShowCompletedQuantity) return null;

    switch (currentTableView) {
      case AVAILABLE_VIEWS.TASKS: {
        const firstTask = tasks?.[taskIds?.[0]];
        return isCompletedTask(firstTask) ? getCompletionQuantityOfTask(firstTask) : null;
      }
      case AVAILABLE_VIEWS.ITEMS: {
        if (numberOfTasks === numberOfCompletedTasks) {
          const lastTask = tasks?.[taskIds?.[taskIds?.length - 1]];
          return isCompletedTask(lastTask) ? getCompletionQuantityOfTask(lastTask) : null;
        }
      }
    }

    return null;
  }, [currentTableView, tasks, numberOfTasks, numberOfCompletedTasks, taskIds, isShowCompletedQuantity]);
};

export const getCompletedQuantityData = (task) => {
  const completedQuantity = getCompletionQuantityOfTask(task);
  const quantity = task?.task?.quantity || 1;
  let compareStatus = '';

  if (completedQuantity) {
    if (completedQuantity === quantity) {
      compareStatus = 'equal';
    } else if (completedQuantity > quantity) {
      compareStatus = 'higher';
    } else {
      compareStatus = 'lower';
    }
  }

  return { completedQuantity, quantity, compareStatus };
};
