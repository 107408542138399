import { chunk, isEqual } from 'lodash-es';

import {
  clearMarkers,
  clearPopup,
  getDriverMarkerIconUrl,
  getMarkerIcon,
  getUpdatedWorkerData,
  getVisibleDrivers,
  isLocationUpdatedInLast5Days,
  shouldReUpdateCurrentDriverMarker,
  shouldUpdateDriverMarker,
} from '@yojee/ui/map/components/helpers';

import { DISABLED_LEGEND_CONDITION, LEGEND_DRIVER_KEY } from './MapLegend/constants';
import { createPopupClass } from './popup-canvas';

// MARKERS STORAGE
let driversMarkersMapping = {};
const driversPopupsMapping = {};
const driversFramesMapping = {};

// Filter
let WORKER_FILTER = [];
let DISABLED_LEGEND = [];
class MapController {
  /* SET GOOGLE INSTANCE AND START LISTENNING TO DRIVER MOVEMENT EVENTS */

  // Store current workers on a map structure for easy for
  // update driver marker (change color, icon, hidden or not, etc)
  static workerIdDataMap = {};

  static setGoogleInstance(google, onDriverMarkerClick) {
    this.map = google.map;
    this.maps = google.maps;
    this.onDriverMarkerClick = onDriverMarkerClick;

    MapController.monitorDriverMovement();
  }

  static driverMove(data) {
    if (!data || !data.id || !data.location) return;
    const driverID = data.id;
    const newLoc = data.location;
    const marker = driversMarkersMapping[driverID];
    if (!marker || !marker.getPosition()) {
      //TODO: DRAW A NEW DRIVER
      return;
    }
    if (!driversFramesMapping[driverID]) {
      driversFramesMapping[driverID] = [];
    }
    const frames = driversFramesMapping[driverID];
    frames.push(newLoc);
  }

  static setWorkerFilter(filter) {
    if (!isEqual(WORKER_FILTER, filter)) {
      WORKER_FILTER = filter;
    }
  }

  static setDisabledLegend(legend) {
    DISABLED_LEGEND = legend;
  }

  static showOptimsiedDrivers(driverIDs) {
    const { map } = this;
    if (driverIDs.length > 0) {
      Object.keys(driversMarkersMapping).forEach((driverID) => {
        if (!driverIDs.includes(driverID)) {
          driversMarkersMapping[driverID].setMap(null);
        }
      });
    } else {
      Object.values(driversMarkersMapping).forEach((marker) => {
        marker.setMap(map);
      });
    }
  }
  /* END OF DERIVER MOVEMENT */

  /* START OF MARKER DRAWING */
  static drawWorkerMarkers(workers, showStartLocation = false) {
    this.workerIdDataMap = workers.reduce((acc, worker) => {
      acc[worker.id] = worker;
      return acc;
    }, {});

    const { map, maps, onDriverMarkerClick } = this;
    /* Clear driver markers */
    Object.values(driversMarkersMapping).forEach((marker) => {
      marker.setMap(null);
    });
    driversMarkersMapping = {};

    const visibleWorkers = getVisibleDrivers(workers, WORKER_FILTER);

    const chunkedData = chunk(visibleWorkers, 100);
    const drawMarkers = () => {
      const data = chunkedData.shift() || [];
      MapController.addMarkers(data, maps, map, onDriverMarkerClick, showStartLocation);
      if (chunkedData.length > 0) {
        setTimeout(() => {
          drawMarkers();
        }, 200);
      }
    };
    drawMarkers();
  }

  static focusOnWorker(worker) {
    const { map } = this;
    const marker = driversMarkersMapping[worker.id];
    if (!marker || !marker.getPosition) return;
    const workerLoc = marker.getPosition();
    map.setCenter({ lat: workerLoc.lat(), lng: workerLoc.lng() });
    map.setZoom(30);
  }

  static addMarkers = (workerData, maps, map, onDriverMarkerClick, showStartLocation = false) => {
    // Ignore rendering driver markers that do not exist in WORKER_FILTER.
    if (WORKER_FILTER.length > 0 && workerData.length > 0) {
      workerData = workerData.filter((worker) => WORKER_FILTER.includes(worker.id));
    }

    if (DISABLED_LEGEND.some((legendKey) => LEGEND_DRIVER_KEY.includes(legendKey)) > 0 && workerData.length > 0) {
      workerData = workerData.filter(
        (worker) => !DISABLED_LEGEND.some((legendKey) => DISABLED_LEGEND_CONDITION[legendKey]?.(worker))
      );
    }

    const startZIndex = 12000;
    const Popup = createPopupClass(maps);
    workerData.forEach((worker, zIndex) => {
      if (!worker) return;

      const iconUrl = getDriverMarkerIconUrl(worker);
      const icon = getMarkerIcon(iconUrl, new maps.Size(22, 22));
      const bigIcon = getMarkerIcon(iconUrl, new maps.Size(24, 24));

      if (worker.location && worker.location.lat && worker.location.lng) {
        const content = document.createElement('div');
        const indicator = document.createElement('div');
        indicator.className = 'online-indicator';
        indicator.style.backgroundColor = worker.status === 'on_duty' ? '#8ec94d' : 'lightgray';
        content.appendChild(indicator);
        const namediv = document.createElement('div');
        namediv.innerText = worker.name;
        content.appendChild(namediv);

        const infoWindow = new Popup(new maps.LatLng(worker.location.lat, worker.location.lng), content);
        const workerMarker = new maps.Marker({
          position: worker.location,
          map,
          icon,
          zIndex: startZIndex + zIndex,
        });
        workerMarker.addListener('mouseover', function () {
          workerMarker.setIcon(bigIcon);
          infoWindow.setMap(map);
        });
        workerMarker.addListener('mouseout', function () {
          workerMarker.setIcon(icon);
          infoWindow.setMap(null);
        });
        workerMarker.addListener('click', function () {
          onDriverMarkerClick(worker);
        });
        driversMarkersMapping[worker.id] = workerMarker;
        driversPopupsMapping[worker.id] = infoWindow;
      }

      if (showStartLocation && worker.start_location) {
        const contentStartLocation = document.createElement('div');
        const indicator = document.createElement('div');
        indicator.className = 'online-indicator';
        indicator.style.backgroundColor = worker.status === 'on_duty' ? '#8ec94d' : 'lightgray';
        contentStartLocation.appendChild(indicator);
        const namediv = document.createElement('div');
        namediv.innerText = worker.name + ' start location';
        contentStartLocation.appendChild(namediv);

        const infoStartLocationWindow = new Popup(
          new maps.LatLng(worker.start_location.lat, worker.start_location.lng),
          contentStartLocation
        );
        const workerStartLocationMarker = new maps.Marker({
          position: worker.start_location,
          map,
          icon,
          zIndex: startZIndex + zIndex,
          opacity: 0.5,
        });
        workerStartLocationMarker.addListener('mouseover', function () {
          workerStartLocationMarker.setIcon(bigIcon);
          infoStartLocationWindow.setMap(map);
        });
        workerStartLocationMarker.addListener('mouseout', function () {
          workerStartLocationMarker.setIcon(icon);
          infoStartLocationWindow.setMap(null);
        });
        driversMarkersMapping[worker.id + '_start_location'] = workerStartLocationMarker;
        driversPopupsMapping[worker.id + '_start_location'] = infoStartLocationWindow;
      }
    });
  };

  static updateWorkersOnGoingTasksCount({
    worker_id: workerId,
    ongoing_tasks_count: currentWorkerGoingTaskCount,
    worker_id_and_ongoing_tasks_count_map: workerIdAndOngoingTasksCountMap,
  }) {
    const { map, maps, onDriverMarkerClick } = this;
    if (!map || !maps) return;

    if (workerId) {
      const updatedWorkerData = getUpdatedWorkerData(this.workerIdDataMap, workerId, currentWorkerGoingTaskCount);
      this.workerIdDataMap[workerId] = updatedWorkerData;

      clearMarkers(driversMarkersMapping, workerId);
      MapController.addMarkers([updatedWorkerData], maps, map, onDriverMarkerClick);
    } else {
      const updatedWorkersData = [];

      for (const [workerId, onGoingTaskCount] of Object.entries(workerIdAndOngoingTasksCountMap)) {
        const updatedWorkerData = getUpdatedWorkerData(this.workerIdDataMap, workerId, onGoingTaskCount);
        this.workerIdDataMap[workerId] = updatedWorkerData;

        clearMarkers(driversMarkersMapping, workerId);
        updatedWorkersData.push(updatedWorkerData);
      }

      MapController.addMarkers(updatedWorkersData, maps, map, onDriverMarkerClick);
    }
  }

  static updateWorkersData(newWorkerData) {
    const { map, maps, onDriverMarkerClick } = this;
    if (!map || !maps) return;

    const workerId = newWorkerData.id;

    if (workerId) {
      const currentDriverData = this.workerIdDataMap[workerId];
      const updatedWorkerData = { ...this.workerIdDataMap[workerId], ...newWorkerData };
      this.workerIdDataMap[workerId] = updatedWorkerData;

      if (!isLocationUpdatedInLast5Days(updatedWorkerData)) {
        clearMarkers(driversMarkersMapping, updatedWorkerData.id);
        clearPopup(driversPopupsMapping, updatedWorkerData.id);
      } else if (shouldUpdateDriverMarker(currentDriverData, updatedWorkerData)) {
        clearMarkers(driversMarkersMapping, workerId);
        clearPopup(driversPopupsMapping, workerId);
        MapController.addMarkers([updatedWorkerData], maps, map, onDriverMarkerClick);
      }
    }
  }

  static monitorDriverMovement = () => {
    let waitDuration = 5000;
    const driverIDs = Object.keys(driversFramesMapping);
    if (driverIDs.length > 0) {
      waitDuration = 20;
      driverIDs.forEach((drID) => {
        const frames = driversFramesMapping[drID];
        if (frames.length >= 1) {
          const nextLoc = frames.shift();
          const marker = driversMarkersMapping[drID];
          if (marker && marker.setPosition) {
            marker.setPosition(nextLoc);
          }
        } else {
          delete driversFramesMapping[drID];
        }
      });
    }
    setTimeout(() => {
      MapController.monitorDriverMovement();
    }, waitDuration);
  };

  static autoUpdateDriverMarkers = () => {
    const { map, maps, onDriverMarkerClick } = this;
    if (!map || !maps) return;

    return setInterval(() => {
      const workersNeedUpdate = [];

      Object.values(this.workerIdDataMap).forEach((worker) => {
        const currentWorkerMarker = driversMarkersMapping[worker.id];

        if (!isLocationUpdatedInLast5Days(worker)) {
          clearMarkers(driversMarkersMapping, worker.id);
        } else if (shouldReUpdateCurrentDriverMarker(currentWorkerMarker, worker)) {
          workersNeedUpdate.push(worker);
          clearMarkers(driversMarkersMapping, worker.id);
        }
      });

      MapController.addMarkers(workersNeedUpdate, maps, map, onDriverMarkerClick);
    }, 60 * 1000);
  };
}

export default MapController;
