import Dialog from '@material-ui/core/Dialog';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Carousel from 'react-material-ui-carousel';
import { useSelector } from 'react-redux';

import { STEP_PROPERTY_TYPES } from '@yojee/helpers/stepHelpers';

const DATETIME_FORMAT = 'DD/MM/YYYY - hh:mmA';

const ItemLogsTable = ({ item: { logs, final_delivery_pod_details: podDetails } }) => {
  const { t } = useTranslation();

  const LOG_MESSAGES_MAP = {
    // Task completed group
    task_completed: t('was completed'),
    pickup_task_completed: t('The pick-up was completed'),
    dropoff_task_completed: t('The drop-off was completed'),
    pickup_empty_task_completed: t('The pick-up (empty) was completed'),
    pickup_origin_task_completed: t('The pick-up (origin) was completed'),
    dropoff_destination_task_completed: t('The drop-off (destination) was completed'),
    dropoff_empty_task_completed: t('The drop-off (empty) was completed'),

    // Task failed group
    task_marked_as_failed: t('The pick-up/drop-off failed'),
    pickup_marked_as_failed: t('The pick-up failed'),
    dropoff_marked_as_failed: t('The drop-off failed'),
    pickup_empty_marked_as_failed: t('The pick-up (empty) failed'),
    pickup_origin_marked_as_failed: t('The pick-up (origin) failed'),
    dropoff_destination_marked_as_failed: t('The drop-off (destination) failed'),
    dropoff_empty_marked_as_failed: t('The drop-off (empty) failed'),

    // Task cancelled group
    task_cancelled: t('The pick-up/drop-off was cancelled'),
    pickup_task_cancelled: t('The pick-up was cancelled'),
    dropoff_task_cancelled: t('The drop-off was cancelled'),
    pickup_empty_task_cancelled: t('The pick-up (empty) was cancelled'),
    pickup_origin_task_cancelled: t('The pick-up (origin) was cancelled'),
    dropoff_destination_task_cancelled: t('The drop-off (destination) was cancelled'),
    dropoff_empty_task_cancelled: t('The drop-off (empty) was cancelled'),

    // Task arrived group
    pickup_task_arrived: t('The Driver arrived at the pick-up'),
    dropoff_task_arrived: t('The Driver arrived at the drop-off'),
    pickup_empty_task_arrived: t('The Driver arrived at the pick-up (empty)'),
    pickup_origin_task_arrived: t('The Driver arrived at the pick-up (origin)'),
    dropoff_destination_task_arrived: t('The Driver arrived at the drop-off (destination)'),
    dropoff_empty_task_arrived: t('The Driver arrived at the drop-off (empty)'),
    task_arrived: t('The Driver arrived at'),

    // ORDER_ITEM_LOG_MESSAGES
    order_item_cancelled: t('An item was cancelled'),
    order_item_updated: t('An item was updated'),
    border_crossing_completed: t('The border crossing is completed'),

    // ORDER_LOG_MESSAGES
    order_created_by_transfer: t('The order was created'),
    order_created: t('The order was created'),
    order_cancelled: t('The order was cancelled'),
    order_updated: t('The order was updated'),
    order_completed: t('The order was completed'),

    // TASK_GROUP_LOG_MESSAGES
    task_group_cancelled: t('The tasks were cancelled'),
    worker_completed_task_group: t('The Driver completed the tasks'),
  };

  const [sliderOpen, setSliderOpen] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState(null);
  const displaySettings = useSelector((state) => state.company.companyInfo.copy);
  const getFormattedLog = (log, key) => {
    const _eventTime = log['event_at'] ? log['event_at'] : null;
    const _logEvent = log['event'] ? log['event'] : null;
    let _logDesc = log['description'] ? log['description'] : null;
    if (!_logEvent && !_logDesc) {
      return null;
    }
    const _toIdx = _logDesc.indexOf('to');
    const _byIdx = _logDesc.indexOf('by');
    const _atIdx = _logDesc.indexOf('at');
    const _dueToIdx = _logDesc.indexOf('due to');
    switch (_logEvent.toLowerCase()) {
      case 'task_completed':
        if (_byIdx > -1 && _atIdx > -1) {
          _logDesc = _logDesc.substring(0, _byIdx) + _logDesc.substring(_atIdx);
        }
        break;
      case 'task_marked_as_failed':
        if (_byIdx > -1 && _atIdx > -1) {
          _logDesc = _logDesc.substring(0, _byIdx) + _logDesc.substring(_atIdx);
        }
        if (_dueToIdx > -1 && _logDesc.length > _dueToIdx + 6) {
          _logDesc = _logDesc.substring(0, _dueToIdx + 6) + _logDesc.substring(_dueToIdx + 6).toLowerCase();
        }
        break;
      case 'task_transferred':
        if (_toIdx > -1) {
          _logDesc = _logDesc.substring(0, _toIdx + 2) + ' partner';
        }
        break;
      case 'task_assigned':
        if (_toIdx > -1) {
          _logDesc = _logDesc.substring(0, _toIdx + 2) + ' driver';
        }
        break;
      case 'order_item_cancelled':
        _logDesc = 'Item cancelled';
        break;
      case 'worker_accepted_item':
        if (_byIdx > -1) {
          _logDesc = _logDesc.substring(0, _byIdx + 2) + ' driver';
        }
        break;
      default:
        break;
    }

    return {
      event_at: _eventTime ? _eventTime : null,
      event: _logEvent,
      description: _logDesc,
      metadata: log['metadata'],
      step_property: log['step_property'],
      key,
    };
  };

  if (logs.task_logs) {
    const filterEvent = logs.task_logs.filter((t) => t.event === 'task_eta');
    const regex = new RegExp('ETA: (.*?) --');
    if (filterEvent && filterEvent.length > 0) {
      filterEvent.forEach((e) => {
        const metadata = e['metadata'] ? e['metadata'] : null;
        const description = e['description'];
        if (metadata && description) {
          const eta = metadata.eta;
          if (regex.test(description) && eta) {
            const extractedValue = regex.exec(description)[1];
            const etaLocalTime = moment(eta);
            e.description = description.replace(extractedValue, etaLocalTime.toString());
          }
        }
      });
    }
  }

  const _showEvent = (eventType) => {
    return (
      eventType &&
      (displaySettings['auditlog.event_types']?.includes(eventType.toLowerCase()) ||
        displaySettings['auditlog.event_types_map']?.[eventType.toLowerCase()]) &&
      LOG_MESSAGES_MAP.hasOwnProperty(eventType.toLowerCase())
    );
  };

  const filteredAndOrderedLogs = useMemo(() => {
    const getFilteredAndArrangedLogs = (logs) => {
      if (!logs || logs.length < 1) {
        return [];
      }
      let filteredLogs = [];
      const logTypesOrder = ['order_logs', 'order_item_logs', 'task_logs', 'task_group_logs'];
      logTypesOrder.forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(logs, key)) {
          filteredLogs = [
            ...filteredLogs,
            ...logs[key]
              .filter((log) => _showEvent(log['event']))
              .map((selectedLog) => getFormattedLog(selectedLog, key)),
          ];
        }
      });

      return filteredLogs.length > 0
        ? filteredLogs.sort((a, b) => {
            if (a['event_at'] && b['event_at']) {
              const timeDiff = new Date(b['event_at']).getTime() - new Date(a['event_at']).getTime();
              if (timeDiff === 0) {
                if (a.metadata?.step_type) {
                  if (a.metadata?.step_type === 'pickup' && b.metadata?.step_type === 'dropoff') {
                    return 1;
                  } else if (a.metadata?.step_type === 'dropoff' && b.metadata?.step_type === 'pickup') {
                    return -1;
                  }
                }

                return logTypesOrder.indexOf(a['key']) - logTypesOrder.indexOf(b['key']);
              }

              return timeDiff;
            }
            return 0;
          })
        : [];
    };

    return logs ? getFilteredAndArrangedLogs(logs) : [];
  }, [logs, displaySettings]);

  if (filteredAndOrderedLogs.length < 1) {
    return null;
  }

  const renderEventDateTime = (dateTime) => {
    const offset = moment().utcOffset() / 60;
    let offsetLabel = '';
    if (offset > 0) {
      offsetLabel = ` +${offset}`;
    } else {
      offsetLabel = ` ${offset}`;
    }

    return `${moment.utc(dateTime).local().format(DATETIME_FORMAT)} (GMT${offsetLabel})`;
  };

  const _handleEventMessage = (log) => {
    let message;
    const stepProperty = log.step_property;
    const stepType = log.metadata?.step_type;
    const prefix = `${stepType ? stepType + '_' : ''}${stepProperty ? stepProperty + '_' : ''}`;

    if (log.event === 'task_arrived') {
      const key = `${prefix}task_arrived`;
      message = LOG_MESSAGES_MAP[key] ? LOG_MESSAGES_MAP[key] : LOG_MESSAGES_MAP['task_arrived'];
    } else if (log.event === 'task_marked_as_failed') {
      const key = `${prefix}marked_as_failed`;
      message = LOG_MESSAGES_MAP[key] ? LOG_MESSAGES_MAP[key] : LOG_MESSAGES_MAP['task_marked_as_failed'];
    } else if (log.event === 'task_completed') {
      const key = `${prefix}task_completed`;
      message = LOG_MESSAGES_MAP[key] ? LOG_MESSAGES_MAP[key] : LOG_MESSAGES_MAP['task_completed'];
    } else if (log.event === 'task_cancelled') {
      const key = `${prefix}task_cancelled`;
      message = LOG_MESSAGES_MAP[key] ? LOG_MESSAGES_MAP[key] : LOG_MESSAGES_MAP['task_cancelled'];
    } else {
      message = LOG_MESSAGES_MAP[log['event']].replace('{{date_time}}', renderEventDateTime(log['event_at']));
    }

    return message;
  };

  const _openSlider = (index) => {
    setActiveImageIndex(index);
    setSliderOpen(true);
  };

  const renderDestinationCompletedEventRow = (log, id) => {
    return (
      <tr key={`item-log-${id}`} className="order-delivered-event-row" data-cy={`tracking-item-log-row-${id}`}>
        <td data-cy="date-column">{renderEventDateTime(log['event_at'])}</td>
        <td className="item-row-description" data-cy="event-column">
          <div className="bold" data-cy="event-column-delivered-status">
            <Trans>Delivered</Trans>
          </div>
          <div className="light" data-cy="event-column-delivered-message">
            <Trans>Your order has been successfully delivered</Trans>
          </div>
          {podDetails?.signature_recipient_name && (
            <div className="semi-bold" data-cy="event-column-delivered-sign">
              <Trans>Signed for by</Trans>: {podDetails?.signature_recipient_name}
            </div>
          )}
          <div className="images-container" data-cy="event-column-delivered-image">
            {[...(podDetails?.pod_photo_urls ?? []), ...(podDetails?.signature_photo_urls ?? [])].map((img, index) => (
              <div className="image-container" onClick={() => _openSlider(index)}>
                <img src={img} alt="reference" />
              </div>
            ))}
          </div>
        </td>
      </tr>
    );
  };

  const _renderSlider = () => {
    return (
      <Dialog open={sliderOpen} onClose={() => setSliderOpen(false)} className="images-slider-dialog" maxWidth="md">
        <div className="body">
          <Carousel autoPlay={false} index={activeImageIndex}>
            {[...(podDetails?.pod_photo_urls ?? []), ...(podDetails?.signature_photo_urls ?? [])].map((img, index) => (
              <div className="image-container" onClick={() => _openSlider(index)}>
                <img src={img} alt="reference" />
              </div>
            ))}
          </Carousel>
        </div>
      </Dialog>
    );
  };

  const renderEventRow = (log, id) => {
    if (log.event === 'task_completed' && log.step_property === STEP_PROPERTY_TYPES.destination) {
      return renderDestinationCompletedEventRow(log, id);
    }

    return _renderGeneralEventRow(log, id);
  };

  const _renderGeneralEventRow = (log, id) => {
    return (
      <tr key={`item-log-${id}`} data-cy={`tracking-item-log-row-${id}`}>
        <td data-cy="date-column">{renderEventDateTime(log['event_at'])}</td>
        <td className="item-row-description" data-cy="event-column">
          {_handleEventMessage(log)}
        </td>
      </tr>
    );
  };

  return (
    <div className="item-log-container" data-cy="tracking-item-log-updates">
      {filteredAndOrderedLogs.length > 0 && (
        <>
          <h3>
            <Trans>Updates</Trans>:
          </h3>
          <table data-cy="tracking-item-log-table">
            <thead>
              <tr>
                <th>
                  <Trans>Date</Trans>
                </th>
                <th className="item-row-description">
                  <Trans>Event</Trans>
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredAndOrderedLogs.map((log, idx) => {
                if (log['event'] && Object.prototype.hasOwnProperty.call(LOG_MESSAGES_MAP, log['event'])) {
                  return renderEventRow(log, idx);
                }
                return null;
              })}
            </tbody>
          </table>
        </>
      )}
      {_renderSlider()}
    </div>
  );
};

export default ItemLogsTable;
