import { Trans } from 'react-i18next';

import { getValue } from '@yojee/helpers/access-helper';
import { BROADCAST_STATUS, getBroadcastStatus } from '@yojee/helpers/broadcast-helper';
import { getTaskState } from '@yojee/helpers/TaskStateHelper';

import { TASK_STATUS, TRANSFER_STATUS } from './taskConstants';

export const filterTasksRelatedToTransfer = ({ tasks, selectedTaskIds }) => {
  if (!tasks || !selectedTaskIds || selectedTaskIds.length === 0) {
    return [];
  }
  return selectedTaskIds.filter((id) => {
    const task = tasks[id];
    return task?.transfer_requested_time;
  });
};

export const TRANSFER_SUB_STATUS = {
  pending: 'pending',
  reported: 'reported',
  completed: 'completed',
  unassigned: 'unassigned',
  assigned: 'assigned',
};

export const TRANSFER_STATUS_KEY_TEXT_MAP = {
  [TRANSFER_STATUS.transferredPending]: <Trans>transferred - pending</Trans>,
  [TRANSFER_STATUS.transferredReported]: <Trans>transferred - reported</Trans>,
  [TRANSFER_STATUS.transferredCompleted]: <Trans>transferred - completed</Trans>,
  [TRANSFER_STATUS.transferredUnassigned]: <Trans>transferred - unassigned</Trans>,
  [TRANSFER_STATUS.transferredAssigned]: <Trans>transferred - assigned</Trans>,
  [TRANSFER_STATUS.transferred]: <Trans>transferred</Trans>,
};

export const getTransferredStatus = (task) => {
  const subStatus = getTransferredSubStatus(task);

  switch (subStatus) {
    case TRANSFER_SUB_STATUS.pending:
      return TRANSFER_STATUS.transferredPending;
    case TRANSFER_SUB_STATUS.reported:
      return TRANSFER_STATUS.transferredReported;
    case TRANSFER_SUB_STATUS.completed:
      return TRANSFER_STATUS.transferredCompleted;
    case TRANSFER_SUB_STATUS.unassigned:
      return TRANSFER_STATUS.transferredUnassigned;
    case TRANSFER_SUB_STATUS.assigned:
      return TRANSFER_STATUS.transferredAssigned;
    default:
      return TRANSFER_STATUS.transferred;
  }
};

export const isTransferredCompletedTask = (task) => {
  return getTransferredSubStatus(task) === TRANSFER_SUB_STATUS.completed;
};

export const filterTransferedCompletedTasks = ({ tasks = [], selectedTaskIds = [] }) => {
  return selectedTaskIds?.filter((id) => getTransferredSubStatus(tasks?.[id]) === TRANSFER_SUB_STATUS.completed);
};

export const getTransferredSubStatus = (task) => {
  if (!isTransferredTask(task)) {
    return undefined;
  }

  if (task?.transfer_state === 'pending' && task?.state !== TASK_STATUS.invalidated) {
    return TRANSFER_SUB_STATUS.pending;
  }

  if (task?.transfer_state === 'accepted') {
    if (
      task?.transfer_task_metadata?.task_info?.state === TASK_STATUS.completed &&
      task?.transfer_task_metadata?.task_info?.completion_time
    ) {
      return TRANSFER_SUB_STATUS.completed;
    }

    if (
      task?.transfer_task_metadata?.task_group_info?.state === 'assigned' &&
      !task?.transfer_task_metadata?.task_info?.completion_time
    ) {
      return TRANSFER_SUB_STATUS.assigned;
    }

    if (
      task?.transfer_task_metadata?.task_info?.associated_task_id &&
      !task?.transfer_task_metadata?.task_info?.completion_time
    ) {
      return TRANSFER_SUB_STATUS.reported;
    }

    if (
      task?.transfer_task_metadata?.task_group_info?.state === 'unassigned' &&
      !task?.transfer_task_metadata?.task_info?.completion_time
    ) {
      return TRANSFER_SUB_STATUS.unassigned;
    }
  }

  return undefined;
};

export const isTransferredTask = (task) => {
  return !!task?.['transfer_requested_time'];
};

export const filterTaskIdsForTransfer = ({ tasks, selectedTaskIds }) => {
  if (!tasks || !selectedTaskIds || selectedTaskIds.length < 0) {
    return [];
  }
  return selectedTaskIds.filter((id) => {
    const taskState = getTaskState(tasks[id]);
    const broadcastState = getBroadcastStatus(tasks[id]);
    return (
      (((taskState === TASK_STATUS.unassigned && broadcastState !== BROADCAST_STATUS.BROADCASTING) ||
        taskState === TASK_STATUS.reported) &&
        tasks[id].transfer_state === 'none') ||
      getValue(tasks[id], 'order_item.transfer_properties.transfer_info.allow_transfer')
    );
  });
};

export const filterTasksIdsForWithdrawTransfer = ({ tasks, selectedTaskIds }) => {
  if (!tasks || !selectedTaskIds || selectedTaskIds.length < 0) {
    return [];
  }
  return selectedTaskIds.filter((id) => {
    const task = tasks[id];
    return task && task.transfer_requested_time && task.order_item.state !== 'completed';
  });
};
